/**
 * @generated SignedSource<<63f64997e2dfae4d0d4fe4d9dd630bfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasManual",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "includeParentOrganisation",
  "value": true
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v0/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "currentOrganisation",
      "value": true
    },
    (v2/*: any*/)
  ],
  "concreteType": "JobTitleConnection",
  "kind": "LinkedField",
  "name": "jobTitles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTitle",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "jobTitles(currentOrganisation:true,includeParentOrganisation:true)"
},
v7 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": "name"
},
v8 = {
  "kind": "Literal",
  "name": "sortDirection",
  "value": "asc"
},
v9 = {
  "alias": null,
  "args": [
    (v2/*: any*/),
    {
      "kind": "Literal",
      "name": "roles",
      "value": [
        "admin",
        "editor",
        "author"
      ]
    },
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": "users(includeParentOrganisation:true,roles:[\"admin\",\"editor\",\"author\"],sortBy:\"name\",sortDirection:\"asc\")"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCia",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasQt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasRisk",
  "storageKey": null
},
v13 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confidentiality",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrity",
  "storageKey": null
},
v16 = [
  (v0/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  (v3/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "NormParagraph",
  "kind": "LinkedField",
  "name": "normParagraphs",
  "plural": true,
  "selections": (v16/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewResourceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v9/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ResourceForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewResourceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasRtoRpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RiskAttitude",
            "kind": "LinkedField",
            "name": "riskAttitude",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "criticalValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxRange",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "InformationSourceConnection",
        "kind": "LinkedField",
        "name": "informationSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InformationSource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availability",
                "storageKey": null
              },
              (v14/*: any*/),
              (v0/*: any*/),
              (v15/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "informationSources(sortBy:\"name\",sortDirection:\"asc\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraphs",
        "plural": true,
        "selections": (v16/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "normRequirementType",
            "value": "resource"
          }
        ],
        "concreteType": "NormRequirement",
        "kind": "LinkedField",
        "name": "normRequirements",
        "plural": true,
        "selections": [
          (v14/*: any*/),
          (v0/*: any*/),
          (v15/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normParagraphIds",
            "storageKey": null
          },
          (v3/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": "normRequirements(normRequirementType:\"resource\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          (v0/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLabels",
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "Supplier",
        "kind": "LinkedField",
        "name": "suppliers",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": "suppliers(sortBy:\"name\",sortDirection:\"asc\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Norm",
        "kind": "LinkedField",
        "name": "norms",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          (v17/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a67028f92195e02b01432865611e6242",
    "id": null,
    "metadata": {},
    "name": "NewResourceQuery",
    "operationKind": "query",
    "text": "query NewResourceQuery {\n  currentOrganisation {\n    databaseId\n    hasManual\n    id\n  }\n  jobTitles(currentOrganisation: true, includeParentOrganisation: true) {\n    nodes {\n      __typename\n      databaseId\n      id\n      name\n    }\n  }\n  users(includeParentOrganisation: true, roles: [\"admin\", \"editor\", \"author\"], sortBy: \"name\", sortDirection: \"asc\") {\n    __typename\n    databaseId\n    id\n    name\n  }\n  ...ResourceForm_query\n}\n\nfragment CiaForm_query on Query {\n  currentOrganisation {\n    hasCia\n    hasQt\n    hasRisk\n    hasRtoRpo\n    riskAttitude {\n      criticalValue\n      maxRange\n      id\n    }\n    id\n  }\n}\n\nfragment ConfirmTakeNormParagraphs_query_24MDBS on Query {\n  normRequirements(normRequirementType: \"resource\") {\n    databaseId\n    name\n    normParagraphIds\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n\nfragment NormSelector_query on Query {\n  norms {\n    databaseId\n    name\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n\nfragment ResourceForm_query on Query {\n  informationSources(sortBy: \"name\", sortDirection: \"asc\") {\n    nodes {\n      availability\n      confidentiality\n      databaseId\n      integrity\n      name\n      id\n    }\n  }\n  manualParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n  normRequirements(normRequirementType: \"resource\") {\n    confidentiality\n    databaseId\n    integrity\n    name\n    normParagraphIds\n    id\n  }\n  organisations {\n    companyName\n    databaseId\n    hasCia\n    hasLabels\n    hasQt\n    hasRisk\n    id\n  }\n  suppliers(sortBy: \"name\", sortDirection: \"asc\") {\n    databaseId\n    name\n    id\n  }\n  ...CiaForm_query\n  ...ConfirmTakeNormParagraphs_query_24MDBS\n  ...NormSelector_query\n}\n"
  }
};
})();

node.hash = "a3e10a1f581b64bdf2efa38cb1d8286c";

module.exports = node;
