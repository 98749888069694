/**
 * @generated SignedSource<<507a012ca4a6b5a53727fd332506ab98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSelfAssessment",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerFieldName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SatQuestionField",
  "kind": "LinkedField",
  "name": "satQuestionFields",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SatAnswer",
  "kind": "LinkedField",
  "name": "satAnswers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "satQuestionFieldId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentTemplateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SelfAssessmentTemplate",
        "kind": "LinkedField",
        "name": "selfAssessmentTemplate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SatQuestion",
            "kind": "LinkedField",
            "name": "satQuestions",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SatField",
                "kind": "LinkedField",
                "name": "satFields",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentTemplateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SelfAssessmentTemplate",
        "kind": "LinkedField",
        "name": "selfAssessmentTemplate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SatQuestion",
            "kind": "LinkedField",
            "name": "satQuestions",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SatField",
                "kind": "LinkedField",
                "name": "satFields",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0a66627e4583d27e99732f524b46c75",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentTemplateQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentTemplateQuery(\n  $id: ID!\n) {\n  currentOrganisation {\n    hasSelfAssessment\n    id\n  }\n  selfAssessmentTemplate(id: $id) {\n    answerFieldName\n    databaseId\n    id\n    name\n    satQuestionFields {\n      databaseId\n      id\n      name\n      position\n    }\n    satQuestions {\n      databaseId\n      id\n      position\n      satAnswers {\n        answer\n        databaseId\n        id\n        position\n      }\n      satFields {\n        databaseId\n        name\n        satQuestionFieldId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "2f443e612c4ce9c0aa4dff52e7b9baa8";

module.exports = node;
