/**
 * @generated SignedSource<<a63c64ab50ab78aefcc3b663fafa4430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDpia",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor"
    ]
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessNorms",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confidentialDeviations",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorisationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v0/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "members(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthorisationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v0/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": "members(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "861686d3345ab146f56a11d72a41291d",
    "id": null,
    "metadata": {},
    "name": "AuthorisationQuery",
    "operationKind": "query",
    "text": "query AuthorisationQuery {\n  organisations {\n    databaseId\n    id\n    hasDpia\n    members(roles: [\"admin\", \"editor\"], sortBy: \"name\", sortDirection: \"asc\") {\n      accessNorms\n      confidentialDeviations\n      databaseId\n      isPo\n      user {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "2dd6454da05a1bd8e8eaf403473ea967";

module.exports = node;
