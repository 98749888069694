import styled from 'styled-components';

const gutterWidth = 15;

export const Container = styled.div`
  padding-right: ${gutterWidth}px;
  padding-left: ${gutterWidth}px;
  margin-right: auto;
  margin-left: auto;
  ${(props) =>
    props.fluid === undefined &&
    `
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
  `}
  width: 100%;
`;

const numberOfColumns = 12;

export const Col = styled.div`
  ${({ center }) => center && 'display: flex; align-items: center;'}
  ${({ column }) => column && 'display: flex; flex-direction: column;'}
  ${({ left }) => left && 'align-items: center; display: flex; justify-content: flex-start;'}
  ${({ right }) => right && 'display: flex; justify-content: flex-end;'}

  ${({ hideOnly }) => {
    switch (hideOnly) {
      case 'xs':
        return '@media (max-width: 576px) { display: none; }';
      case 'sm':
        return '@media (max-width: 768px) { display: none; }';
      case 'md':
        return '@media (max-width: 992px) { display: none; }';
      case 'lg':
        return '@media (max-width: 1200px) { display: none; }';
      case 'xl':
        return '@media (max-width: 1400px) { display: none; }';
      default:
    }
  }}

  ${(props) =>
    props.xs
      ? `
  flex: ${props.xs ? `0 0 ${(props.xs / numberOfColumns) * 100}%` : '0 0 auto'};
  max-width: ${((props.xs || numberOfColumns) / numberOfColumns) * 100}%;
    `
      : `
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  `}

  ${(props) => props.offsetXs && `margin-left: ${(props.offsetXs / numberOfColumns) * 100}%;`};

  @media (min-width: 576px) {
    ${(props) =>
      props.sm &&
      `
        flex: ${props.sm ? `0 0 ${(props.sm / numberOfColumns) * 100}%` : '0 0 auto'};
        max-width: ${((props.sm || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetSm && `margin-left: ${(props.offsetSm / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 768px) {
    ${(props) =>
      props.md &&
      `
        flex: ${props.md ? `0 0 ${(props.md / numberOfColumns) * 100}%` : '0 0 auto'};
        max-width: ${((props.md || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetMd && `margin-left: ${(props.offsetMd / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 992px) {
    ${(props) =>
      props.lg &&
      `
        flex: ${props.lg ? `0 0 ${(props.lg / numberOfColumns) * 100}%` : '0 0 auto'};
        max-width: ${((props.lg || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetLg && `margin-left: ${(props.offsetLg / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 1200px) {
    ${(props) =>
      props.xl &&
      `
        flex: ${props.xl ? `0 0 ${(props.xl / numberOfColumns) * 100}%` : '0 0 auto'};
        max-width: ${((props.xl || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetXl && `margin-left: ${(props.offsetXl / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 1400px) {
    ${(props) =>
      props.xxl &&
      `
        flex: ${props.xxl ? `0 0 ${(props.xxl / numberOfColumns) * 100}%` : '0 0 auto'};
        max-width: ${((props.xxl || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetXxl && `margin-left: ${(props.offsetXxl / numberOfColumns) * 100}%;`};
  }
`;

export const Row = styled.div`
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  ${({ flexY }) => flexY && '& { flex-direction: column; }'}
  ${({ border, spacer }) =>
    border &&
    `&:after {
        border-bottom: 1px solid var(--tertiary-color);
        content: "";
        margin: 0 ${gutterWidth * spacer}px 0.75rem;
        padding-bottom: 1.5rem;
        width: 100%;
      }
    `}

  ${({ gap }) => gap && `gap: ${gap}rem;`}

  ${({ spacer }) => `
    margin-right: -${gutterWidth * spacer}px;
    margin-left: -${gutterWidth * spacer}px;

  & > ${Col} {
    padding-left: ${gutterWidth * spacer}px; padding-right: ${gutterWidth * spacer}px;`}
    ${({ border }) => border && '&:nth-child(n + 2) { border-left: 1px solid var(--tertiary-color); }'}
  }
`;

Row.defaultProps = { spacer: 1 };
