/**
 * @generated SignedSource<<6dac498f6623680b153bfe22e8162d9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDpia",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DpiaQuestionnairesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DpiaQuestionnaireList_organisation"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "DpiaQuestionnaireList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DpiaQuestionnairesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasChildOrganisations",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DpiaQuestionnairePagination",
        "kind": "LinkedField",
        "name": "dpiaQuestionnairePagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DpiaQuestionnaire",
            "kind": "LinkedField",
            "name": "dpiaQuestionnaires",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "progress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "participants",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4e99889bb71660c89a866f906ad1d5b",
    "id": null,
    "metadata": {},
    "name": "DpiaQuestionnairesQuery",
    "operationKind": "query",
    "text": "query DpiaQuestionnairesQuery(\n  $limit: Int\n  $page: Int\n  $sortBy: String\n  $sortDirection: String\n) {\n  currentOrganisation {\n    hasDpia\n    ...DpiaQuestionnaireList_organisation\n    id\n  }\n  ...DpiaQuestionnaireList_query_35Jp96\n}\n\nfragment DpiaQuestionnaireList_organisation on Organisation {\n  ...DpiaQuestionnaire_organisation\n  hasChildOrganisations\n}\n\nfragment DpiaQuestionnaireList_query_35Jp96 on Query {\n  dpiaQuestionnairePagination(limit: $limit, page: $page, sortBy: $sortBy, sortDirection: $sortDirection) {\n    dpiaQuestionnaires {\n      id\n      ...DpiaQuestionnaire_dpiaQuestionnaire\n    }\n    totalCount\n  }\n}\n\nfragment DpiaQuestionnaire_dpiaQuestionnaire on DpiaQuestionnaire {\n  createdAt\n  databaseId\n  description\n  name\n  progress\n  participants\n  organisation {\n    companyName\n    id\n  }\n  user {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment DpiaQuestionnaire_organisation on Organisation {\n  hasChildOrganisations\n}\n"
  }
};
})();

node.hash = "3a6256cd9ac38e2a3622d74b110dbb4f";

module.exports = node;
