/**
 * @generated SignedSource<<7042bed52ad7fcad2bb90e077839c5df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualRealisations",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "normRealisations",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "focusArea",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAudit",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LegacyAuditFindingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "AuditFindingsCluster",
            "kind": "LinkedField",
            "name": "auditFindingsClusters",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": "auditFindingsClusters(sortBy:\"name\",sortDirection:\"asc\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditQuestion",
            "kind": "LinkedField",
            "name": "auditQuestions",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LegacyAuditQuestion_auditQuestion"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LegacyAuditFindingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "AuditFindingsCluster",
            "kind": "LinkedField",
            "name": "auditFindingsClusters",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": "auditFindingsClusters(sortBy:\"name\",sortDirection:\"asc\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditQuestion",
            "kind": "LinkedField",
            "name": "auditQuestions",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditAnswer",
                "kind": "LinkedField",
                "name": "auditAnswers",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "filename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditFindingsCluster",
                    "kind": "LinkedField",
                    "name": "auditFindingsCluster",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditQuestionnaire",
                    "kind": "LinkedField",
                    "name": "auditQuestionnaire",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "interviewee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatar",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraph",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f09de006b6973ea852ec02548dfa77a",
    "id": null,
    "metadata": {},
    "name": "LegacyAuditFindingsQuery",
    "operationKind": "query",
    "text": "query LegacyAuditFindingsQuery(\n  $id: ID!\n) {\n  audit(id: $id) {\n    databaseId\n    manualRealisations\n    name\n    normRealisations\n    auditFindingsClusters(sortBy: \"name\", sortDirection: \"asc\") {\n      databaseId\n      focusArea\n      name\n      rating\n      id\n    }\n    auditQuestions {\n      id\n      ...LegacyAuditQuestion_auditQuestion\n    }\n    id\n  }\n  currentOrganisation {\n    hasAudit\n    id\n  }\n}\n\nfragment AttachmentLink_attachment on Attachment {\n  filename\n  url\n}\n\nfragment LegacyAuditQuestionAnswer_auditAnswer on AuditAnswer {\n  id\n  answer\n  attachments {\n    id\n    ...AttachmentLink_attachment\n  }\n  auditFindingsCluster {\n    name\n    id\n  }\n  auditQuestionnaire {\n    interviewee {\n      avatar\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment LegacyAuditQuestion_auditQuestion on AuditQuestion {\n  question\n  auditAnswers {\n    id\n    ...LegacyAuditQuestionAnswer_auditAnswer\n  }\n  manualParagraph {\n    number\n    id\n  }\n}\n"
  }
};
})();

node.hash = "536a17818eccf6c51494f3418fd664ed";

module.exports = node;
