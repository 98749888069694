/**
 * @generated SignedSource<<1e218e541c10f97694e8bec3710b8f9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organisationId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasManual",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availability",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confidentiality",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "NormParagraph",
  "kind": "LinkedField",
  "name": "normParagraphs",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewJobTitleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobTitleForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewJobTitleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCia",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLabels",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasQt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasRisk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasRtoRpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RiskAttitude",
            "kind": "LinkedField",
            "name": "riskAttitude",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "criticalValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxRange",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "InformationSourceConnection",
        "kind": "LinkedField",
        "name": "informationSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InformationSource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "informationSources(sortBy:\"name\",sortDirection:\"asc\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraphs",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "jobTitle",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "normRequirementType",
            "value": "job_title"
          }
        ],
        "concreteType": "NormRequirement",
        "kind": "LinkedField",
        "name": "normRequirements",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normParagraphIds",
            "storageKey": null
          },
          (v2/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": "normRequirements(normRequirementType:\"job_title\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          (v6/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResourceConnection",
        "kind": "LinkedField",
        "name": "resources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Resource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "resources(sortBy:\"name\",sortDirection:\"asc\")"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Supplier",
        "kind": "LinkedField",
        "name": "suppliers",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": "suppliers(sortBy:\"name\",sortDirection:\"asc\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Norm",
        "kind": "LinkedField",
        "name": "norms",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0a08144ac263b72675c0204c0b3cb47",
    "id": null,
    "metadata": {},
    "name": "NewJobTitleQuery",
    "operationKind": "query",
    "text": "query NewJobTitleQuery {\n  currentMember {\n    organisationId\n    id\n  }\n  currentOrganisation {\n    hasManual\n    id\n  }\n  ...JobTitleForm_query\n}\n\nfragment CiaForm_query on Query {\n  currentOrganisation {\n    hasCia\n    hasQt\n    hasRisk\n    hasRtoRpo\n    riskAttitude {\n      criticalValue\n      maxRange\n      id\n    }\n    id\n  }\n}\n\nfragment ConfirmTakeNormParagraphs_query_2ma0In on Query {\n  normRequirements(normRequirementType: \"job_title\") {\n    databaseId\n    name\n    normParagraphIds\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n\nfragment JobTitleForm_query on Query {\n  currentOrganisation {\n    hasCia\n    hasLabels\n    hasQt\n    hasRisk\n    id\n  }\n  informationSources(sortBy: \"name\", sortDirection: \"asc\") {\n    nodes {\n      availability\n      confidentiality\n      databaseId\n      integrity\n      name\n      id\n    }\n  }\n  manualParagraphs {\n    availability\n    confidentiality\n    databaseId\n    integrity\n    name\n    number\n    jobTitle {\n      name\n      id\n    }\n    id\n  }\n  normRequirements(normRequirementType: \"job_title\") {\n    confidentiality\n    databaseId\n    integrity\n    name\n    normParagraphIds\n    id\n  }\n  organisations {\n    companyName\n    databaseId\n    id\n  }\n  resources(sortBy: \"name\", sortDirection: \"asc\") {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  suppliers(sortBy: \"name\", sortDirection: \"asc\") {\n    databaseId\n    name\n    id\n  }\n  ...CiaForm_query\n  ...ConfirmTakeNormParagraphs_query_2ma0In\n  ...NormSelector_query\n}\n\nfragment NormSelector_query on Query {\n  norms {\n    databaseId\n    name\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "daac3b7c1a2bc50187dab11768f5cf84";

module.exports = node;
