/**
 * @generated SignedSource<<1570229e2192a76f302d368ab2d6b2bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasRisk",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "includeParentOrganisation",
  "value": true
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v0/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "currentOrganisation",
      "value": true
    },
    (v2/*: any*/)
  ],
  "concreteType": "JobTitleConnection",
  "kind": "LinkedField",
  "name": "jobTitles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTitle",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "jobTitles(currentOrganisation:true,includeParentOrganisation:true)"
},
v6 = {
  "alias": null,
  "args": [
    (v2/*: any*/),
    {
      "kind": "Literal",
      "name": "roles",
      "value": [
        "admin",
        "editor",
        "author"
      ]
    },
    {
      "kind": "Literal",
      "name": "sortBy",
      "value": "name"
    },
    {
      "kind": "Literal",
      "name": "sortDirection",
      "value": "asc"
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": "users(includeParentOrganisation:true,roles:[\"admin\",\"editor\",\"author\"],sortBy:\"name\",sortDirection:\"asc\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewRiskQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NewRiskForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewRiskQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc0a9eecc16f8aae359749abe594758b",
    "id": null,
    "metadata": {},
    "name": "NewRiskQuery",
    "operationKind": "query",
    "text": "query NewRiskQuery {\n  currentOrganisation {\n    databaseId\n    hasRisk\n    id\n  }\n  jobTitles(currentOrganisation: true, includeParentOrganisation: true) {\n    nodes {\n      __typename\n      databaseId\n      id\n      name\n    }\n  }\n  users(includeParentOrganisation: true, roles: [\"admin\", \"editor\", \"author\"], sortBy: \"name\", sortDirection: \"asc\") {\n    __typename\n    databaseId\n    id\n    name\n  }\n  ...NewRiskForm_query\n}\n\nfragment NewRiskForm_query on Query {\n  currentMember {\n    organisationId\n    id\n  }\n}\n"
  }
};
})();

node.hash = "9978b725d368fa58210104f6be11c5a8";

module.exports = node;
