/**
 * @generated SignedSource<<6b4024eea0bd3a2c7a68bfc3b3c971ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "normParagraphIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "normRequirementTypes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasRisk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentOrganisationId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "normParagraphIds",
    "variableName": "normParagraphIds"
  },
  {
    "kind": "Variable",
    "name": "normRequirementTypes",
    "variableName": "normRequirementTypes"
  },
  {
    "kind": "Variable",
    "name": "organisationIds",
    "variableName": "organisationIds"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NormRequirementsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NormRequirementFilters_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NormRequirementList_query"
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "NormRequirementListRefetch_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NormRequirementsUseLabels_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NormRequirementsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLabels",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NormParagraphConnection",
        "kind": "LinkedField",
        "name": "normParagraphs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "NormRequirementPagination",
        "kind": "LinkedField",
        "name": "normRequirementPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NormRequirement",
            "kind": "LinkedField",
            "name": "normRequirements",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "confidentiality",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrity",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "normRequirementTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4a002405519741bf909dad82c737a8e",
    "id": null,
    "metadata": {},
    "name": "NormRequirementsQuery",
    "operationKind": "query",
    "text": "query NormRequirementsQuery(\n  $limit: Int\n  $normParagraphIds: [Int!]\n  $normRequirementTypes: [String!]\n  $organisationIds: [Int!]\n  $page: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n) {\n  currentOrganisation {\n    hasRisk\n    id\n  }\n  organisations {\n    id\n    parentOrganisationId\n  }\n  ...NormRequirementFilters_query\n  ...NormRequirementList_query\n  ...NormRequirementListRefetch_query_nGSUy\n  ...NormRequirementsUseLabels_query\n}\n\nfragment NormRequirementFilters_query on Query {\n  normParagraphs {\n    nodes {\n      databaseId\n      name\n      number\n      id\n    }\n  }\n}\n\nfragment NormRequirementListRefetch_query_nGSUy on Query {\n  normRequirementPagination(limit: $limit, normParagraphIds: $normParagraphIds, normRequirementTypes: $normRequirementTypes, organisationIds: $organisationIds, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    normRequirements {\n      id\n      ...NormRequirement_normRequirement\n    }\n    totalCount\n  }\n}\n\nfragment NormRequirementList_query on Query {\n  organisations {\n    databaseId\n    id\n    parentOrganisationId\n  }\n}\n\nfragment NormRequirement_normRequirement on NormRequirement {\n  confidentiality\n  databaseId\n  description\n  integrity\n  name\n  normRequirementTypes\n  normParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n}\n\nfragment NormRequirementsUseLabels_query on Query {\n  organisations {\n    id\n    hasLabels\n    parentOrganisationId\n  }\n}\n"
  }
};
})();

node.hash = "5db8f1a58eab98029506955d268567fd";

module.exports = node;
