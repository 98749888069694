/**
 * @generated SignedSource<<24e7a94612e56321ce74187a08b1ab18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auditProgrammeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userIds",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "asEmployee",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "asOwner",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auditInterviewProcessesCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitleId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandatory",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remarks",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditDay",
  "kind": "LinkedField",
  "name": "auditDay",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v19 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v20 = [
  (v4/*: any*/)
],
v21 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor"
    ]
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  },
  {
    "kind": "Literal",
    "name": "statuses",
    "value": [
      "active",
      "pending"
    ]
  }
],
v22 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v23 = [
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditAgendaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditDay",
            "kind": "LinkedField",
            "name": "auditDays",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditInterview",
                "kind": "LinkedField",
                "name": "auditInterviews",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v11/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v4/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditInterviewProcess",
                    "kind": "LinkedField",
                    "name": "auditInterviewProcesses",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ManualParagraph",
                        "kind": "LinkedField",
                        "name": "manualParagraph",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobTitle",
                    "kind": "LinkedField",
                    "name": "jobTitle",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": true,
                    "selections": (v20/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v21/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v19/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditAgendaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditDay",
            "kind": "LinkedField",
            "name": "auditDays",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditInterview",
                "kind": "LinkedField",
                "name": "auditInterviews",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v11/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v4/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditInterviewProcess",
                    "kind": "LinkedField",
                    "name": "auditInterviewProcesses",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ManualParagraph",
                        "kind": "LinkedField",
                        "name": "manualParagraph",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v17/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobTitle",
                    "kind": "LinkedField",
                    "name": "jobTitle",
                    "plural": false,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": true,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v22/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v21/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v22/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      }
    ]
  },
  "params": {
    "cacheID": "747da399d9673bb7d915a1ae6f6f4ec6",
    "id": null,
    "metadata": {},
    "name": "AuditAgendaQuery",
    "operationKind": "query",
    "text": "query AuditAgendaQuery(\n  $id: ID!\n) {\n  audit(id: $id) {\n    auditProgrammeId\n    databaseId\n    name\n    id\n    userIds\n    auditDays {\n      databaseId\n      date\n      id\n      auditInterviews {\n        asEmployee\n        asOwner\n        auditInterviewProcessesCount\n        databaseId\n        endTime\n        id\n        jobTitleId\n        mandatory\n        name\n        remarks\n        startTime\n        status\n        userIds\n        auditInterviewProcesses {\n          manualParagraph {\n            databaseId\n            name\n            number\n            id\n          }\n          id\n        }\n        auditDay {\n          id\n        }\n        jobTitle {\n          databaseId\n          name\n          id\n        }\n        users {\n          name\n          id\n        }\n      }\n    }\n    auditProgramme {\n      name\n      id\n    }\n    users {\n      databaseId\n      name\n      id\n    }\n  }\n  users(roles: [\"admin\", \"editor\"], sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\", \"pending\"]) {\n    databaseId\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "1dd4a52e9f9e6e0d0a96f9c49a14a460";

module.exports = node;
