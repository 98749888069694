/**
 * @generated SignedSource<<8a0ce2f4d1016e92f2e07005b6e4f423>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDpia",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewDpiaQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewDpiaQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ddcd9396b04fbffb3f5fca81eacc052",
    "id": null,
    "metadata": {},
    "name": "NewDpiaQuestionnaireQuery",
    "operationKind": "query",
    "text": "query NewDpiaQuestionnaireQuery {\n  currentOrganisation {\n    hasDpia\n    id\n  }\n}\n"
  }
};
})();

node.hash = "5202a0bb7829d01966075fa6743154eb";

module.exports = node;
