/**
 * @generated SignedSource<<ad4ca1b566215156e4b64e99515f1539>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadTable"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "manualParagraphId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tableId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textBlockId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasManual",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasProduct",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "manualParagraphId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v8 = {
  "condition": "loadTable",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "tableId"
        }
      ],
      "concreteType": "Table",
      "kind": "LinkedField",
      "name": "table",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "columns",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "textBlockId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraph",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "TextBlock",
        "kind": "LinkedField",
        "name": "textBlock",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TableFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraph",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "TextBlock",
        "kind": "LinkedField",
        "name": "textBlock",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4e78a10e854196b201e3e8167f8e85f",
    "id": null,
    "metadata": {},
    "name": "TableQuery",
    "operationKind": "query",
    "text": "query TableQuery(\n  $loadTable: Boolean!\n  $manualParagraphId: ID!\n  $tableId: ID!\n  $textBlockId: ID!\n) {\n  currentOrganisation {\n    hasManual\n    hasProduct\n    id\n  }\n  manualParagraph(id: $manualParagraphId) {\n    name\n    number\n    id\n  }\n  table(id: $tableId) @include(if: $loadTable) {\n    id\n    databaseId\n    columns\n  }\n  textBlock(id: $textBlockId) {\n    name\n    id\n  }\n  ...TableFormFragment\n}\n\nfragment TableFormFragment on Query {\n  textBlock(id: $textBlockId) {\n    id\n    databaseId\n  }\n}\n"
  }
};
})();

node.hash = "840d0b58da84cdbed173a3ed79deaa65";

module.exports = node;
